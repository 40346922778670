.wide {
    background-color: rgba(223, 223, 223, 1);
    /*background-repeat: no-repeat;
    background-image: url(../images/header_nj.jpg);
    background-size: cover;
    background-position: center center;*/
    width: 100%;
    padding: 0;
    opacity: 1;
    visibility: inherit;
    z-index: 20;

    @media (max-width: $sm) { 
        background-color:rgba(223, 223, 223, 1);
        background-image: none;
    }
}
.wide .container {
	/* min-height: 80vh; */
    position: relative;
}


.wide h3{
    color: #ffffff;
}

.wide h1{
   /* color: #464846;*/
    padding-top: 4%;
    padding-bottom: 4%;
    font-size: 1.5em;

    @media (min-width: $md){
        margin-bottom: 0;
    }
}

.wide .submit-wrap{
    text-align: right;
}


.wide .sd-footer-widget-title span {
    @media (max-width: $sm) {
     color:#304254;
 }
}

.wide .show {
    @media (max-width: $sm) {
        display: block;
    }
}

.color-blue {
    color: #2b98cc;
}

.color-orange {
    color: #f39200;
}

.margin-img {
    margin-top: 30px;
}