/*---------------------------------------------------------------------*/
/*BREAKPOINTS*/
/*---------------------------------------------------------------------*/
/*---------------------------------------------------------------------*/
/*VALUES*/
/*---------------------------------------------------------------------*/
/*---------------------------------------------------------------------*/
/*COLORS*/
/*---------------------------------------------------------------------*/
/* ------------------------------------------------------------------------ */
/* 01. General
/* ------------------------------------------------------------------------ */
/* load the default Google font */
@import url(//fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,800,300,600,700);
@font-face {
  font-family: star;
  font-style: normal;
  font-weight: 400; }

body {
  height: 100%;
  background-color: #fff;
  color: #888888;
  font-family: "Open Sans", Arial, Tahoma, sans-serif;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  -webkit-text-stroke: 0.001px transparent;
  -webkit-font-smoothing: antialiased;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    body {
      font-size: 16px; } }

input[type="email"],
input[type="submit"],
input[type="text"],
textarea {
  -webkit-appearance: none; }

section {
  padding: 25px 0;
  /* Every odd section has a gray background except the first one (starts counting at 3) */ }
  section:nth-of-type(2n+3) {
    background-color: #f6f6f6; }
  @media (min-width: 992px) {
    section {
      padding: 50px 0; } }

/* ------------------------------------------------------------------------ */
/* 02. Typography Styling
/* ------------------------------------------------------------------------ */
/* Links */
a {
  color: #329bcd;
  outline: none;
  text-decoration: none;
  -webkit-transition: color .2s ease-in;
  transition: color .2s ease-in; }
  a:hover {
    text-decoration: none;
    color: #329bcd; }
  a:focus, a:active {
    outline: none; }

/* Blockquotes */
blockquote {
  border: none; }
  blockquote p {
    margin: 0; }

/* Titles */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #304254;
  font-weight: 600;
  line-height: normal;
  margin-top: 0; }

h1, .sizeh1 {
  font-size: 3em; }

h2 {
  font-size: 2em;
  text-align: center;
  margin-bottom: 1em;
  /* @media(min-width: $md){
        margin-bottom: 2em;
    }*/ }

.sizeh2 {
  font-size: 2em; }

h3, .sizeh3 {
  font-size: 1.5em;
  margin-bottom: .5em; }

h4, .sizeh4 {
  font-size: 1.3em; }

h5, .sizeh5 {
  font-size: 1.2em; }

h6, .sizeh6 {
  font-size: 1.1em; }

p {
  margin-bottom: .5em; }

label {
  font-weight: inherit; }

/* Images Styling */
img {
  max-width: 100%;
  height: auto; }

img.alignleft {
  float: left;
  vertical-align: middle;
  margin-right: 20px; }

img.alignright {
  float: right;
  vertical-align: middle;
  margin-left: 20px; }

img.aligncenter {
  display: block;
  margin: 0 auto;
  clear: both; }

.aligncenter {
  margin: 0 auto;
  clear: both;
  display: block; }

/* Lists */
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0; }

.btn-orange {
  display: inline-block;
  background: #f39200;
  color: #ffffff;
  padding: .5em 1em;
  border-radius: 4px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out; }
  .btn-orange:hover {
    background: #f06d25;
    color: #ffffff; }

.btn-bleu {
  display: inline-block;
  background: #2b98cc;
  color: #ffffff;
  padding: .5em 1em;
  border-radius: 4px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out; }
  .btn-bleu:hover {
    background: #304254;
    color: #ffffff; }

.border-box {
  border-style: solid;
  border-width: 1px;
  border-color: #f6f6f6;
  margin: 0px; }

.img-container {
  text-align: center; }

.img-responsive {
  margin: 0 auto; }

.text-justify {
  text-align: justify; }

.margin-bottom100 {
  margin-bottom: 100px; }

.margin-bottom20 {
  margin-bottom: 20px; }

.margin-top100 {
  margin-top: 100px; }

.padding-top100 {
  padding-top: 100px; }

.padding-top10 {
  padding-top: 10px; }

.padding-bottom100 {
  padding-bottom: 100px; }

.padding-top35 {
  padding-top: 35px; }

.padding-bottom75 {
  padding-bottom: 75px; }

.margin-top20 {
  margin-top: 20px; }

.icon-confiance {
  color: #f39200;
  margin-right: 15px;
  font-size: 24px; }

.margin-bottom30 {
  margin-bottom: 30px; }

.padding-bottom35 {
  padding-bottom: 35px; }

@media (min-width: 992px) {
  .border-temoignages {
    border-right: 1px solid #b9b3b3; } }

.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

/* style helpers */
.sd-alpha {
  margin-left: 0; }

.sd-omega {
  margin-right: 0; }

.sd-center {
  text-align: center; }

.sd-centered-tabs .ult_tabmenu {
  display: block;
  text-align: center; }

.sd-centered-tabs .ult_tab_li {
  float: none; }

.sd-right {
  text-align: right; }

.sd-inline {
  display: inline; }

.sd-margin-left {
  margin-left: 30px; }

.sd-margin-right {
  margin-right: 30px; }

.sd-margin-top {
  margin-top: 20px; }

.sd-margin-bottom {
  margin-bottom: 20px; }

.sd-margin-none {
  margin: 0;
  overflow: hidden; }

.margin-top-custom {
  margin-top: 10%; }

.sd-padding-left {
  padding-left: 15px; }

.sd-padding-right {
  padding-right: 15px; }

.sd-padding-left-none {
  padding-left: 0; }

.sd-padding-right-none {
  padding-right: 0; }

.sd-padding-none {
  padding: 0; }

.sd-display-none {
  display: none; }

.sd-display-inline-block {
  display: inline-block; }

.sd-display-table {
  display: table; }

.sd-overflow {
  overflow: hidden; }

.sd-text-background,
.sd-text-background-dark,
.sd-text-bg-white {
  background-color: #91a1b4;
  border-radius: 3px;
  display: inline-block;
  padding: 10px 25px; }

.sd-text-background {
  font-size: 12px; }

.sd-text-background-dark {
  background-color: #435061; }

.sd-text-bg-white {
  background-color: #fff; }

.sd-bold {
  font-weight: bold; }

.sd-light {
  font-weight: 300; }

.sd-small-text {
  font-size: 12px;
  line-height: 12px; }

.sd-large-text {
  font-size: 18px; }

.sd-clear {
  clear: both; }

.sd-float-none {
  float: none; }

.sd-border-top {
  border-top: 1px solid #dfe5e9;
  display: inline-block;
  margin-top: 10px;
  padding-top: 10px; }

.sd-border-bottom {
  border-bottom: 1px solid #dfe5e9;
  display: inline-block;
  margin-bottom: 10px;
  padding-bottom: 10px; }

.sd-border-none {
  border: none; }

.spacer-50 {
  height: 50px; }

.spacer-70 {
  height: 70px; }

.spacer-100 {
  height: 100px; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

/* background transition */
.sd-bg-trans {
  -webkit-transition: background .3s linear;
  transition: background .3s linear; }

.sd-opacity-trans {
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out; }
  .sd-opacity-trans:hover {
    opacity: .8; }

.sd-link-trans {
  -webkit-transition: color .3s ease-in;
  transition: color .3s ease-in; }

.sd-border-trans {
  -webkit-transition: border .3s ease-out;
  transition: border .3s ease-out; }

.sd-all-trans {
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }

.capitalize {
  text-transform: capitalize; }

.sd-list-style li {
  list-style: none;
  padding: 0 0 5px 20px;
  position: relative; }
  .sd-list-style li:last-child {
    padding-bottom: 0; }
  .sd-list-style li::before {
    content: "\f111";
    color: #329bcd;
    font-family: FontAwesome;
    font-size: 15px;
    left: 0;
    position: absolute;
    top: 0; }

.sd-position-relative {
  position: relative; }

.sd-subtitle {
  color: #a1a7a5;
  font-size: 15px;
  font-weight: normal; }

.sd-colored {
  color: #329bcd; }

.sd-left-col img {
  height: auto;
  max-width: 100%; }

.sd-left {
  float: left; }

/* ------------------------------------------------------------------------ */
/* 03. Header
/* ------------------------------------------------------------------------ */
#sd-header {
  background-color: #FFFFFF;
  width: 100%;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: background-color .3s ease-out;
  transition: background-color .3s ease-out; }
  #sd-header.scrolled {
    background-color: white; }

.sd-transparent-bg {
  background: none;
  position: absolute; }

.sd-transparent-bg-mobile {
  background-color: #fff; }

/* header top */
.sd-header-top {
  background-color: #06418A;
  color: #fff;
  font-size: 13px;
  height: 50px;
  line-height: 50px;
  width: 100%; }

@media (min-width: 768px) {
  .sd-logo-menu {
    height: 110px; } }

/* logo */
.sd-logo {
  float: left;
  margin: 20px 0;
  padding: 0;
  width: 180px; }
  @media (min-width: 768px) {
    .sd-logo {
      width: 300px; } }

.sd-logo-express {
  float: left;
  margin: 20px 0;
  padding: 0;
  width: 270px; }
  @media (min-width: 768px) {
    .sd-logo-express {
      width: 370px; } }

.sd-logo-text {
  color: #fff;
  font-size: 20px; }

/* top phone number */
.sd-top-phone {
  color: #f6f6f6;
  float: left;
  border: none;
  margin: 20px 0 0;
  padding: 0;
  text-align: center; }
  @media (min-width: 768px) {
    .sd-top-phone {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      margin-left: 20px;
      margin-top: 30px;
      padding: 5px 0 5px 20px; } }

.sd-top-phone span {
  display: block; }

.sd-call-text {
  font-size: 12px;
  line-height: 12px;
  padding-bottom: 5px; }

.sd-top-ph-number {
  font-size: 16px;
  line-height: 16px; }

.sd-top-ph-number a {
  color: #f6f6f6; }

.gototoform :hover {
  cursor: pointer; }

/* ------------------------------------------------------------------------ */
/* 04. Navigation
/* ------------------------------------------------------------------------ */
.sd-boxed .sd-menu-nav {
  margin: 0 -14px 0 -16px; }

.sd-boxed .sd-menu-nav ul > li:last-child a {
  padding-right: 15px; }

.sd-pull-left-top-nav {
  float: left; }

.sd-pull-left-top-nav ul li:first-child a {
  padding-left: 0; }

.sd-header-top-nav ul li a {
  color: #848a90; }
  .sd-header-top-nav ul li a:hover {
    color: #329bcd; }

.sd-menu-wrapper {
  float: right;
  margin-top: 40px;
  margin-bottom: 40px; }
  @media only screen and (max-width: 992px) {
    .sd-menu-wrapper {
      display: none; } }

.sd-menu-wrapper ul {
  list-style: none; }

.sd-menu-content {
  clear: both; }

.mega-sub-menu {
  padding: 0 -10px; }

.sd-menu-nav {
  font-family: "Open Sans", Tahoma, sans-serif; }

.sd-free-quote,
.sd-quote-form .wpcf7-submit {
  border-radius: 4px;
  box-shadow: 0 -4px rgba(0, 0, 0, 0.2) inset;
  color: #fff;
  display: inline-block;
  float: right;
  font-size: 13px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  margin: 28px 0;
  outline: none;
  padding: 0 20px;
  position: relative; }
  .sd-free-quote:hover,
  .sd-quote-form .wpcf7-submit:hover {
    box-shadow: 0 -3px rgba(0, 0, 0, 0.2) inset;
    color: #fff;
    opacity: 1;
    top: 1px;
    cursor: pointer; }
  .sd-free-quote:active,
  .sd-quote-form .wpcf7-submit:active {
    box-shadow: 0 0 rgba(0, 0, 0, 0.2) inset;
    color: #fff;
    opacity: 1;
    top: 3px; }
  @media (max-width: 768px) {
    .sd-free-quote,
    .sd-quote-form .wpcf7-submit {
      float: none;
      margin-bottom: 20px; } }

@media (max-width: 768px) {
  .sd-logo-menu-content {
    text-align: center; } }

/* top bar menu */
.sd-top-bar-nav ul {
  list-style: none; }

/* sticky menu */
.sd-sticky-header {
  left: 0;
  width: 100%;
  z-index: 1000; }

.sticky-wrapper {
  height: auto; }

.is-sticky .sd-header-style3 {
  padding: 0; }

.is-sticky .sd-header-style3 .sd-menu-wrapper {
  padding: 0; }

.is-sticky .sd-sticky-header {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  margin: 0; }

.sd-boxed .is-sticky .sd-stick {
  left: 0; }

.mega-sd-menu-button a {
  display: inline-block;
  padding: 0 20px;
  position: relative; }

#mega-menu-main-header-menu > li.mega-sd-menu-button > a {
  background: none;
  border: 1px solid;
  border-color: #329bcd;
  border-radius: 3px;
  color: #329bcd; }
  #mega-menu-main-header-menu > li.mega-sd-menu-button > a:hover {
    border-color: #329bcd; }

.sd-menu-nav .mega-menu-toggle {
  display: none; }

ul.mega-sub-menu,
li.mega-menu-item,
a.mega-menu-link {
  -webkit-transition: none;
  transition: none;
  border-radius: 0 0 0 0;
  box-shadow: none;
  background: none;
  border: 0;
  bottom: auto;
  box-sizing: border-box;
  clip: auto;
  color: #435061;
  display: block;
  float: none;
  font-family: inherit;
  font-size: 13px;
  height: auto;
  left: auto;
  line-height: 30px;
  list-style-type: none;
  margin: 0;
  min-height: 0;
  opacity: 1;
  outline: none;
  overflow: visible;
  padding: 0;
  position: relative;
  right: auto;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  top: auto;
  vertical-align: baseline;
  visibility: inherit;
  width: auto; }

#mega-menu-main-header-menu {
  visibility: visible;
  text-align: left;
  padding: 0px 0px 0px 0px; }

#mega-menu-main-header-menu > li.mega-menu-item {
  margin: 0 10px 0 0;
  display: inline-block;
  height: auto; }

#mega-menu-main-header-menu > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link,
#mega-menu-main-header-menu > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link {
  background: transparent;
  color: #f6f6f6;
  font-weight: normal;
  text-decoration: none;
  border-color: #329bcd; }

#mega-menu-main-header-menu > li.mega-menu-item.mega-toggle-on > a.mega-menu-link,
#mega-menu-main-header-menu > li.mega-menu-item > a.mega-menu-link:hover {
  background: transparent;
  color: #000000;
  font-weight: normal;
  text-decoration: none;
  border-color: #000000; }

#mega-menu-main-header-menu > li.mega-menu-item > a.mega-menu-link {
  border-top: 1px solid rgba(255, 255, 255, 0);
  border-left: 1px solid rgba(255, 255, 255, 0);
  border-right: 1px solid rgba(255, 255, 255, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  outline: none;
  text-decoration: none;
  padding: 0px 10px 0px 10px;
  line-height: 40px;
  font-weight: normal;
  height: 40px;
  vertical-align: baseline;
  text-align: left;
  width: auto;
  display: block;
  color: #000000;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px; }

#mega-menu-main-header-menu a.mega-menu-link {
  cursor: pointer;
  display: inline;
  -webkit-transition: background 200ms linear, color 200ms linear;
  transition: background 200ms linear, color 200ms linear; }

#mega-menu-main-header-menu li.mega-menu-flyout.mega-menu-item-has-children > a.mega-menu-link::after,
#mega-menu-main-header-menu li.mega-menu-flyout li.mega-menu-item-has-children > a.mega-menu-link::after,
#mega-menu-main-header-menu > li.mega-menu-item-has-children > a.mega-menu-link::after {
  content: '\f140';
  display: inline-block;
  font-family: dashicons;
  margin: 0 0 0 6px;
  vertical-align: top;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  color: inherit; }

/* sidr mobile menu */
.sidr {
  background-color: #2a2e30;
  display: none;
  height: 100%;
  overflow-x: none;
  overflow-y: auto;
  position: absolute;
  position: fixed;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: 999999; }

.sidr li a {
  color: #fff;
  display: block;
  padding: 10px 20px; }

.sidr .sidr-inner {
  padding: 0 0 15px; }

.sidr .sidr-inner > p {
  margin-left: 15px;
  margin-right: 15px; }

.sidr.right {
  left: auto;
  right: -260px; }

.sidr.left {
  left: -260px;
  right: auto; }

.sidr > p {
  margin-left: 15px;
  margin-right: 15px; }

.sidr ul {
  border-top: 1px solid #2f3336;
  margin: 0 0 15px;
  padding: 0; }

.sidr-class-sub-menu,
.sidr-class-mega-sub-menu {
  display: none; }

.sidr ul li {
  border-bottom: 1px solid #2f3336;
  display: block;
  margin: 0;
  padding: 0; }
  .sidr ul li:last-child {
    border: none; }

.sidr ul li:hover > a,
.sidr ul li:hover > span,
.sidr ul li.active > a,
.sidr ul li.active > span,
.sidr ul li.sidr-class-active > a,
.sidr ul li.sidr-class-active > span {
  background-color: #329bcd; }

.sidr ul li a,
.sidr ul li span {
  display: block;
  padding: 10px 15px;
  text-transform: uppercase; }

.sidr ul li span {
  padding-right: 20px; }

.sidr ul li ul {
  margin: 0; }

.sidr ul li ul li a {
  padding-left: 20px; }

.sidr ul li ul li ul li a {
  padding-left: 30px; }

.sidr form {
  margin: 0 15px; }

.sidr input[type="text"],
.sidr input[type="password"],
.sidr input[type="date"],
.sidr input[type="datetime"],
.sidr input[type="email"],
.sidr input[type="number"],
.sidr input[type="search"],
.sidr input[type="tel"],
.sidr input[type="time"],
.sidr input[type="url"],
.sidr textarea,
.sidr select {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  border: none;
  clear: both;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  margin: 0 0 10px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box; }

.sidr input[type=checkbox] {
  clear: none;
  display: inline;
  width: auto; }

.sidr input[type=button],
.sidr input[type=submit] {
  background: #fff;
  color: #333; }
  .sidr input[type=button]:hover,
  .sidr input[type=submit]:hover {
    background: rgba(255, 255, 255, 0.9); }

.sidr-class-menu-item-has-children,
.sidr-class-mega-menu-item-has-children {
  position: relative; }

.sidr-chevron {
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  height: 44px;
  line-height: 28px;
  padding-right: 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 50px; }

.sd-responsive-menu-close {
  cursor: pointer;
  display: block;
  height: 45px;
  line-height: 45px;
  width: 100%; }

.sd-responsive-menu-close i {
  color: #f39200;
  font-size: 18px;
  padding-left: 15px; }

.sd-responsive-menu-close .fa-times {
  float: right;
  margin: 13px 15px 0 0; }

.sidr-class-mega-block-title {
  display: none; }

.sidr-class-recentcomments a {
  display: inline-block; }

.sidr-class-recentcomments span {
  display: inline-block;
  padding: 10px 0 0 30px; }

.sidr-class-sd-minicart-icon {
  position: relative; }

.sidr-class-fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

.sidr-class-fa-shopping-cart::before {
  content: "\f07a"; }

.sidr-class-sd-minicart-icon span {
  padding: 0; }

.sidr-class-sd-minicart-icon .sd-items-count {
  left: 25px;
  right: auto; }

/* toggle repspoinsive menu */
.sd-responsive-menu-toggle {
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  width: auto;
  float: right;
  text-align: center;
  cursor: pointer; }
  @media (min-width: 768px) {
    .sd-responsive-menu-toggle {
      margin-top: 33px; } }
  @media (min-width: 992px) {
    .sd-responsive-menu-toggle {
      display: none;
      margin-top: 20px;
      padding: 0;
      width: 100%; } }

.sd-responsive-menu-toggle a {
  background-color: #000000;
  color: #FFFFFF;
  display: inline-block;
  height: 40px;
  line-height: 42px;
  padding: 0 15px;
  border-radius: 4px; }

@media screen and (max-width: 361px) {
  .menu-toggle-express {
    margin-top: 20px;
    width: auto;
    float: none;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-bottom: 20px; } }

.container-toggle {
  float: right; }
  @media (min-width: 768px) {
    .container-toggle {
      float: none; } }

.logo-link {
  height: 20px; }

.menu-active {
  /* background: 0 0;
    color: #000;
    font-weight: 400;
    text-decoration: none;*/
  border-color: #000 !important; }

.wide {
  background-color: #dfdfdf;
  /*background-repeat: no-repeat;
    background-image: url(../images/header_nj.jpg);
    background-size: cover;
    background-position: center center;*/
  width: 100%;
  padding: 0;
  opacity: 1;
  visibility: inherit;
  z-index: 20; }
  @media (max-width: 768px) {
    .wide {
      background-color: #dfdfdf;
      background-image: none; } }

.wide .container {
  /* min-height: 80vh; */
  position: relative; }

.wide h3 {
  color: #ffffff; }

.wide h1 {
  /* color: #464846;*/
  padding-top: 4%;
  padding-bottom: 4%;
  font-size: 1.5em; }
  @media (min-width: 992px) {
    .wide h1 {
      margin-bottom: 0; } }

.wide .submit-wrap {
  text-align: right; }

@media (max-width: 768px) {
  .wide .sd-footer-widget-title span {
    color: #304254; } }

@media (max-width: 768px) {
  .wide .show {
    display: block; } }

.color-blue {
  color: #2b98cc; }

.color-orange {
  color: #f39200; }

.margin-img {
  margin-top: 30px; }

.wrap-list-counter,
.section-list h3 {
  margin-top: 1em; }
  @media (min-width: 992px) {
    .wrap-list-counter,
    .section-list h3 {
      margin-top: 0; } }

@media (min-width: 992px) {
  .section-list .row:nth-of-type(n+3) {
    margin-top: 2em; } }

.wrap-list-counter {
  text-align: center; }

.list-counter {
  display: inline-block;
  font-size: 2em;
  text-align: center;
  line-height: 60px;
  color: #ffffff;
  width: 60px;
  height: 60px;
  background: #329bcd;
  border-radius: 50%; }

.section-list .wrap-image {
  text-align: center; }

.section-list img {
  max-height: 20em; }

.table {
  border: 1px solid #329bcd; }

.table,
.table th {
  text-align: center; }

.table thead {
  background: #329bcd;
  color: #ffffff; }
  .table thead > tr > th {
    border: none; }

.table td {
  width: 20%; }

.table > tbody > tr > td {
  padding: 1em;
  border-left: 1px solid #329bcd;
  border-right: 1px solid #329bcd;
  border-top: none;
  border-bottom: none;
  vertical-align: middle; }

.mobile-table {
  text-align: center; }

.mobile-table > ul > li {
  margin-bottom: 1em; }

.mobile-table > ul > li > ul {
  border: 1px solid #329bcd; }

.mobile-table > ul > li > ul > li {
  padding: .5em; }
  .mobile-table > ul > li > ul > li:first-of-type {
    background: #329bcd;
    color: #ffffff;
    font-weight: bold; }
  .mobile-table > ul > li > ul > li:nth-of-type(2n+3) {
    background: #f6f6f6; }

.card {
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }
  .card .card-wrap {
    height: 100%;
    padding: 3em 2em;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(211, 211, 211, 0.5);
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    .card .card-wrap div {
      max-width: 100%; }
  .card img {
    margin-bottom: 2em; }
  .card .btn-orange {
    margin-top: 2em; }
  @media (min-width: 768px) {
    .card {
      padding: 0 5px; } }

@media (min-width: 768px) {
  .section-cards .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; } }

/* PRESS LOGOS */
.press-logos {
  overflow: hidden;
  padding: 20px 0; }
  .press-logos img {
    margin-bottom: 1em; }
    @media (min-width: 992px) {
      .press-logos img {
        margin-bottom: 0; } }
  .press-logos .img-container {
    opacity: 0;
    /*
		&.play-animation{
			animation: slide-in .5s forwards ease-out;
		}
		*/ }
  .press-logos .img-container:nth-of-type(1) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .press-logos .img-container:nth-of-type(2) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .press-logos .img-container:nth-of-type(3) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .press-logos .img-container:nth-of-type(4) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  .press-logos .img-container:nth-of-type(5) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s; }
  .press-logos .img-container:nth-of-type(6) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; }

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 1; } }

@keyframes slide-in {
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    opacity: 1; } }

.presses {
  overflow: hidden; }
  .presses .box_header {
    width: auto; }
    .presses .box_header h2 {
      background-color: #329bcd;
      color: #fff;
      font-size: 26px;
      line-height: 36px;
      font-weight: bold;
      float: left;
      overflow: hidden;
      padding: 0 10px;
      margin: 0; }
      @media screen and (max-width: 1199px) {
        .presses .box_header h2 {
          float: none;
          padding: 10px 7px;
          margin: 0;
          line-height: 25px;
          text-align: center; } }
  .presses header {
    overflow: hidden; }
  .presses .border-bottom-blue {
    border-bottom: 1px solid #329bcd; }
  .presses .img_article_desktop {
    display: block;
    height: 250px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    @media (min-width: 1200px) {
      .presses .img_article_desktop {
        height: 140px; } }
    @media (min-width: 992px) {
      .presses .img_article_desktop {
        height: 175px;
        margin-bottom: 0; } }
    @media (min-width: 992px) {
      .presses .img_article_desktop {
        height: 150px; } }
  .presses .author p {
    margin: 10px 0;
    font-size: .8em;
    color: #8899a6; }
  .presses .more {
    float: right;
    font-weight: bold;
    line-height: 35px; }

.points .type {
  /* width: 300px;*/
  border-radius: 5px 5px 5px 5px;
  height: 62px; }
  .points .type p {
    font-family: 'Open Sans';
    font-weight: 800;
    font-size: 29px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    padding-top: 10px; }
  .points .type.green {
    background-color: #1ABC9C;
    /* border-bottom: 3px solid #17846f;*/ }
  .points .type.yellow {
    background-color: #dfd204;
    /*  border-bottom: 3px solid #c6bd25;*/ }
  .points .type.red {
    background-color: #f60000;
    /* border-bottom: 3px solid #b45008;*/ }
  .points .type.orange {
    background-color: #f39200; }
  @media (max-width: 992px) {
    .points .type {
      margin-top: 20px; } }

.points .plan {
  /* width: 300px;*/
  background-color: #f6f6f6;
  border-radius: 0px 0px 5px 5px;
  font-family: 'Open Sans';
  font-size: 90px;
  color: #fff;
  text-align: center; }

.points .content ul {
  list-style: none;
  font-size: 15px;
  font-family: 'Open Sans';
  color: #000;
  padding: 0px;
  margin: 0px; }
  .points .content ul li {
    border-bottom: 1px solid #b9b3b3;
    padding: 0px;
    margin: 0px;
    text-align: center;
    height: 52px;
    line-height: 52px; }

.points .price {
  height: 52px;
  list-style: none;
  font-size: 15px;
  font-family: 'Open Sans';
  color: #000;
  padding: 0px;
  margin: 0px;
  padding: 0px;
  margin: 0px;
  text-align: center;
  height: 52px;
  line-height: 52px; }

.roundedblue {
  /*background: #fff;
  border: 1px solid #e1e1e1;
  border-top: 3px solid #329bcd;*/
  padding: 20px;
  text-align: center;
  height: 350px; }
  .roundedblue .title {
    font-size: 20px;
    margin-top: 0;
    font-weight: 600;
    color: #304254;
    margin-bottom: 20px; }
  .roundedblue .content {
    background: #fff;
    border-radius: 0 4px 4px 4px;
    width: 100%;
    padding: 10px 0; }
    .roundedblue .content div {
      padding: 0;
      width: 100%; }
    .roundedblue .content .bullets {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 35px; }
  .roundedblue .swipe {
    overflow: hidden;
    position: relative; }
    .roundedblue .swipe .swipe-wrap {
      overflow: hidden;
      position: relative; }
      .roundedblue .swipe .swipe-wrap > div {
        float: left;
        width: 100%;
        position: relative; }
    .roundedblue .swipe .mark-star {
      display: inline-block;
      width: 23px;
      height: 22px;
      margin-top: 10px;
      margin-bottom: 25px;
      background-image: url(/assets/images/icons/star_jaune.png); }
      .roundedblue .swipe .mark-star.deux {
        width: 46px; }
      .roundedblue .swipe .mark-star.trois {
        width: 46px; }
      .roundedblue .swipe .mark-star.quatre {
        width: 92px; }
      .roundedblue .swipe .mark-star.cinq {
        width: 115px; }
  .roundedblue .listing {
    text-align: left; }
    .roundedblue .listing ul {
      /*padding-left: 20px;*/
      margin: 0 0 0 2em;
      list-style: none;
      list-style-image: url(/assets/images/icons/check-orange.png); }
    .roundedblue .listing li {
      margin-bottom: 10px; }

#sliding-reviews {
  overflow: hidden;
  visibility: hidden;
  position: relative;
  padding: 0; }

.wrap-avis {
  overflow: hidden;
  position: relative;
  padding: 0; }
  .wrap-avis > li {
    float: left;
    width: 100%;
    position: relative; }

.avis {
  background: #329bcd;
  padding: 2em 3em;
  border-radius: 4px; }
  .avis h4, .avis p {
    color: #ffffff; }
  .avis .signature {
    margin-top: 1em;
    text-align: right;
    font-style: italic; }

.wrap-bullets {
  float: right;
  margin-bottom: .5em; }
  @media (min-width: 992px) {
    .wrap-bullets {
      margin-bottom: 1em; } }

.bullet {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 15px;
  background-color: #f6f6f6;
  box-shadow: 0 0 5px #d3d3d3; }
  .bullet.active {
    /*background-color: $light_blue;*/
    background-color: #f39200; }
  .bullet:hover {
    cursor: pointer; }

.bullet-express {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 15px;
  background-color: #b7b7b7;
  box-shadow: 0 0 5px #d3d3d3; }
  .bullet-express.active {
    /*background-color: $light_blue;*/
    background-color: #f39200; }
  .bullet-express:hover {
    cursor: pointer; }

.bullets-express {
  margin-top: 22px; }

.mgtop30 {
  margin-top: 30px; }

.bg-white {
  background-color: #ffffff !important; }

/* LATEST POSTS */
.sd-events-shortcode .sd-event-date,
.sd-events-page .sd-event-date {
  color: #0191c6;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px; }

.sd-events-shortcode .sd-entry-thumb {
  margin-bottom: 10px; }

.sd-entry-thumb img {
  max-width: 100%;
  margin: 0 auto;
  border: 1px solid #EFEFEF;
  padding: 3px; }

.sd-events-shortcode .sd-event-title {
  color: #2f3c40;
  font-size: 20px;
  font-weight: normal; }

.sd-event-title h2 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: bold; }

.sd-event-time, .sd-event-location {
  display: block;
  font-size: 14px; }

.sd-event-time, .sd-event-location {
  display: block;
  font-size: 14px; }

.sd-learn-more {
  color: #ffffff;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 30px;
  border-radius: 30px;
  border: none;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out; }
  .sd-learn-more:hover {
    color: #ffffff; }

.latest-blog-post figure {
  position: relative; }

.latest-blog-post .sd-learn-more {
  font-size: 15px;
  padding: 10px 15px;
  position: absolute;
  bottom: -20px;
  right: 10px; }

.latest-blog-post h3 {
  margin-top: .5em;
  border-bottom: 1px solid #d3d3d3; }

/*BLOG ITEMS */
.sd-blog-page > .container > .row:nth-of-type(n+2) {
  padding-top: 4em;
  border-top: solid 1px #d3d3d3; }

.sd-blog-entry {
  margin-bottom: 4em; }

.sd-blog-page .sd-entry-thumb,
.sd-blog-page .sd-entry-video {
  margin-bottom: 30px; }

.sd-events-meta {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 20px;
  padding-bottom: 20px; }

.sd-events-page .sd-event-time {
  float: left; }

.sd-events-page .sd-event-location {
  float: right; }

.sd-blog-page .sd-learn-more {
  border: 1px solid #f39200;
  border-radius: 4px;
  float: right;
  margin-top: 15px; }
  .sd-blog-page .sd-learn-more a {
    color: #f39200; }
  .sd-blog-page .sd-learn-more:hover {
    background: #f39200; }
    .sd-blog-page .sd-learn-more:hover a {
      color: #ffffff; }

@media (min-width: 768px) {
  .sd-quote-form {
    padding: 15px 25px; } }

/*TABS*/
.sd-tab-section-title {
  display: inline-block;
  position: relative;
  top: 36px;
  left: 0; }

@media (max-width: 992px) {
  .sd-tab-section-title {
    text-align: center;
    padding-top: 20px;
    position: static;
    top: 0;
    height: auto; } }

@media (min-width: 1200px) {
  .sd-insurance-tabs ul {
    width: 294px; } }

.sd-insurance-tabs ul {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 244px; }

@media (max-width: 992px) {
  .sd-insurance-tabs ul {
    width: auto;
    float: none;
    padding: 0; } }

@media (max-width: 992px) {
  .sd-insurance-tabs ul li span {
    text-align: center; } }

.sd-insurance-tabs ul li span {
  border-bottom: 1px solid #dcdcdc;
  color: #304254;
  display: block;
  font-size: 18px;
  line-height: 20px;
  padding: 30px 20px;
  height: 100px; }

.sd-insurance-tabs img {
  max-width: 112%; }

@media (max-width: 768px) {
  .sd-insurance-tabs img {
    max-width: 100%; } }

@media (min-width: 979px) {
  .sd-insurance-tabs ul li:last-child span {
    border-bottom: none; } }

.sd-panel-wrap {
  padding: 50px 40px;
  position: relative; }

.sd-panel-icon {
  background-color: #00b2ce;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  display: block;
  height: 100px;
  left: 40px;
  padding-top: 50px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 55px; }

.sd-panel-icon i {
  color: #fff;
  font-size: 30px; }

.sd-panel-title {
  font-size: 24px;
  padding-bottom: 50px;
  padding-left: 80px; }

.sd-panel-img {
  height: 424px;
  margin-top: 60px; }

/*FORM*/
.sd-quote-form {
  background-color: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  padding: 1em;
  position: relative;
  margin-bottom: 30px; }

.sd-quote-form div.row {
  margin: 20px 0px; }

.sd-quote-form h4 {
  padding: 5px 10px;
  background-color: #f6f6f6;
  color: #329bcd; }

.sd-quote-form p {
  margin: 0 0 20px; }

.sd-quote-form input,
.sd-quote-form textarea {
  background: none;
  border: 2px solid #e9e9e9;
  border-radius: 0;
  border-width: 0 0 2px;
  color: #989898;
  max-width: 100%;
  padding: 0;
  position: relative;
  z-index: 1; }

.sd-quote-form textarea {
  height: 100px; }

.sd-quote-form input:focus,
.sd-quote-form textarea:focus {
  border: 2px solid #00b2ce;
  border-width: 0 0 2px;
  outline: none; }

.sd-quote-form input {
  color: black; }

.sd-quote-form-product select {
  -webkit-appearance: none;
  background: none;
  border: 2px solid #e9e9e9;
  border-width: 0 0 2px;
  color: #989898;
  height: 50px;
  outline: none;
  width: 100%; }

.sd-quote-form-product span {
  display: inline-block;
  width: 100%;
  position: relative; }

.sd-quote-form-product span::before {
  background-color: #fff;
  border-bottom: 2px solid #e9e9e9;
  content: '';
  display: block;
  height: 50px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 17px; }

.sd-quote-form-product span::after {
  color: #d3d3d3;
  content: '<>';
  font: 15px "Consolas", monospace;
  padding: 0 0 2px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 14px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.form-text {
  border: 1px solid #cfd4db;
  border-radius: 3px;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  width: 100%; }

/*FOOTER*/
#sd-footer {
  background-color: #06418A;
  font-size: .8em;
  padding-top: 20px;
  color: #f6f6f6;
  font-weight: bold; }

.sd-footer-sidebar-widget {
  text-align: center; }

.sd-footer-widget-title {
  color: #fff;
  display: inline-block;
  font-weight: 600; }

.sd-footer-widgets a {
  color: #f6f6f6; }
  .sd-footer-widgets a:hover {
    color: #00b2ce; }

.sd-footer-menu-social {
  border-top: 1px solid #455a70;
  padding: 30px 0; }

.sd-footer-social {
  float: right; }

.sd-footer-social a {
  color: #f6f6f6;
  margin-left: 10px; }
  .sd-footer-social a:first-child {
    margin-left: 0; }
  .sd-footer-social a:hover {
    color: #00b2ce; }

.sd-copyright-wrapper {
  background-color: #063c80; }

.sd-copyright {
  color: #f6f6f6;
  padding: 1em 0;
  position: relative; }

.sd-copyright a {
  color: #f6f6f6; }

.sd-copyright-text {
  margin: 0; }

.section-contact {
  /*[class*='col']:nth-of-type(n+2){
        margin-top: 3em
    }*/ }
  .section-contact h3 {
    text-align: center;
    margin-bottom: 30px; }
  .section-contact li {
    margin-bottom: .5em;
    text-align: center; }
  .section-contact .border-box {
    padding: 2em; }
  @media (min-width: 768px) {
    .section-contact {
      /* ul{
            padding-left: 30% !important; 
        }*/ } }
  @media (min-width: 992px) {
    .section-contact {
      /* [class*='col']:nth-of-type(n+2){
              margin-top: 0
          }*/ } }

.gmap {
  padding: 0; }
  .gmap [class*='col'] {
    padding: 0; }
