/*BLOG ITEMS */
.sd-blog-page > .container > .row:nth-of-type(n+2){
    padding-top: 4em;
    border-top: solid 1px $dark_gray;
}
.sd-blog-entry {
    margin-bottom: 4em;
}

.sd-blog-page .sd-entry-thumb, 
.sd-blog-page .sd-entry-video {
    margin-bottom: 30px;
}

.sd-events-meta {
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.sd-events-page .sd-event-time {
    float: left;
}

.sd-events-page .sd-event-location {
    float: right;
}

.sd-blog-page .sd-learn-more{
    border: 1px solid #f39200;
    border-radius: $border_radius;
    float: right;
    margin-top: 15px;

    a {
        color: #f39200;
    }

    &:hover{
        background: #f39200 ;

        a{
            color: #ffffff;       
        }
    }
}

.sd-quote-form {
    @media (min-width: $sm) {
        padding: 15px 25px;
    }
}