/* LATEST POSTS */

.sd-events-shortcode .sd-event-date, 
.sd-events-page .sd-event-date {
    color: #0191c6;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.sd-events-shortcode .sd-entry-thumb {
    margin-bottom: 10px;
}

.sd-entry-thumb img {
    max-width: 100%;
    margin: 0 auto;
    border: 1px solid #EFEFEF;
    padding: 3px;
}

.sd-events-shortcode .sd-event-title {
    color: #2f3c40;
    font-size: 20px;
    font-weight: normal;
}
.sd-event-title h2{
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: bold;
}

.sd-event-time, .sd-event-location {
    display: block;
    font-size: 14px;
}

.sd-event-time, .sd-event-location {
    display: block;
    font-size: 14px;
}

.sd-learn-more {
    color: #ffffff;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px 30px;
    border-radius: 30px;
    border:none;
    transition: background-color .2s ease-out;

    &:hover{
        color:#ffffff;
    }
}

.latest-blog-post{

    figure{
        position: relative;
    }

    .sd-learn-more{
        font-size: 15px;
        padding: 10px 15px;
        position: absolute;
        bottom: -20px;
        right: 10px;
    }

    h3{
        margin-top: .5em;
        border-bottom: 1px solid #d3d3d3;
    }
}