.roundedblue {
  /*background: #fff;
  border: 1px solid #e1e1e1;
  border-top: 3px solid #329bcd;*/
  padding: 20px;
  text-align: center;
  height: 350px;
  @media (min-width: 992px) {

  }
  .title {
    font-size: 20px;
    margin-top: 0;
    font-weight: 600;
    color: #304254;
    margin-bottom: 20px;
  }
  .content {
    background: #fff;
    border-radius: 0 4px 4px 4px;
    width: 100%;
    padding: 10px 0;
    div {
      padding: 0;
      width: 100%;
    }
    .bullets {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 35px;
    }
  }
  .swipe {
    overflow: hidden;
    position: relative;
    .swipe-wrap {
      overflow: hidden;
      position: relative;
      > div {
        float: left;
        width: 100%;
        position: relative;
      }
    }

    .mark-star {
      display: inline-block;
      width: 23px;
      height: 22px;
      margin-top: 10px;
      margin-bottom: 25px;
      background-image: url(/assets/images/icons/star_jaune.png);
      &.un {

      }
      &.deux {
        width: 46px;
      }
      &.trois {
        width: 46px;
      }
      &.quatre {
        width: 92px;
      }
      &.cinq {
        width: 115px;
      }
    }
  }
  .listing {
    text-align: left;
    ul {
      /*padding-left: 20px;*/
      margin: 0 0 0 2em;
      list-style: none;
      list-style-image: url(/assets/images/icons/check-orange.png);
    }
    li {
      margin-bottom: 10px;
    }
  }
}
