/* style helpers */
.sd-alpha {
    margin-left: 0;
}

.sd-omega {
    margin-right: 0;
}

.sd-center {
    text-align: center;
}

.sd-centered-tabs .ult_tabmenu {
    display: block;
    text-align: center;
}

.sd-centered-tabs .ult_tab_li {
    float: none;
}

.sd-right {
    text-align: right;
}

.sd-inline {
    display: inline;
}

.sd-margin-left {
    margin-left: 30px;
}

.sd-margin-right {
    margin-right: 30px;
}

.sd-margin-top {
    margin-top: 20px;
}

.sd-margin-bottom {
    margin-bottom: 20px;
}

.sd-margin-none {
    margin: 0;
    overflow: hidden;
}

.margin-top-custom {
    margin-top: 10%;
}

.sd-padding-left {
    padding-left: 15px;
}

.sd-padding-right {
    padding-right: 15px;
}

.sd-padding-left-none {
    padding-left: 0;
}

.sd-padding-right-none {
    padding-right: 0;
}

.sd-padding-none {
    padding: 0;
}

.sd-display-none {
    display: none;
}

.sd-display-inline-block {
    display: inline-block;
}

.sd-display-table {
    display: table;
}

.sd-overflow {
    overflow: hidden;
}

.sd-text-background,
.sd-text-background-dark,
.sd-text-bg-white {
    background-color: #91a1b4;
    border-radius: 3px;
    display: inline-block;
    padding: 10px 25px;
}

.sd-text-background {
    font-size: 12px;
}

.sd-text-background-dark {
    background-color: #435061;
}

.sd-text-bg-white {
    background-color: #fff;
}

.sd-bold {
    font-weight: bold;
}

.sd-light {
    font-weight: 300;
}

.sd-small-text {
    font-size: 12px;
    line-height: 12px;
}

.sd-large-text {
    font-size: 18px;
}

.sd-clear {
    clear: both;
}

.sd-float-none {
    float: none;
}

.sd-border-top {
    border-top: 1px solid #dfe5e9;
    display: inline-block;
    margin-top: 10px;
    padding-top: 10px;
}

.sd-border-bottom {
    border-bottom: 1px solid #dfe5e9;
    display: inline-block;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.sd-border-none {
    border: none;
}

.spacer-50 {
    height: 50px;
}

.spacer-70 {
    height: 70px;
}

.spacer-100 {
    height: 100px;
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify;
}


/* background transition */

.sd-bg-trans {
    transition: background .3s linear;
}

.sd-opacity-trans {
    transition: opacity .3s ease-in-out;

    &:hover {
        opacity: .8;
    }
}

.sd-link-trans {
    transition: color .3s ease-in;
}

.sd-border-trans {
    transition: border .3s ease-out;
}

.sd-all-trans {
    transition: all .3s ease-out;
}

.capitalize {
    text-transform: capitalize;
}

.sd-list-style li {
    list-style: none;
    padding: 0 0 5px 20px;
    position: relative;

    &:last-child {
        padding-bottom: 0;
    }

    &::before {
        content: "\f111";
        color: $light_blue;
        font-family: FontAwesome;
        font-size: 15px;
        left: 0;
        position: absolute;
        top: 0;
    }
}

.sd-position-relative {
    position: relative;
}

.sd-subtitle {
    color: #a1a7a5;
    font-size: 15px;
    font-weight: normal;
}

.sd-colored {
    color: $light_blue;
}

.sd-left-col img {
    height: auto;
    max-width: 100%;
}

.sd-left {
    float: left;
}