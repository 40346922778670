/* PRESS LOGOS */

.press-logos{
	overflow: hidden;
	padding: 20px 0;
	

	img{
		margin-bottom: 1em;

		@media (min-width: $md ){
			margin-bottom: 0;
		}
	}

	.img-container{
		//transform: translateX(1000px);
		opacity: 0;
/*
		&.play-animation{
			animation: slide-in .5s forwards ease-out;
		}
		*/
	}

	@for $i from 1 through 6 {
		.img-container:nth-of-type(#{$i}) {
			animation-delay: $i * .1s;
		}
	}
}

@keyframes slide-in{
	100%{
		transform: translateX(0px);
		opacity: 1;
	}
}