/* ------------------------------------------------------------------------ */
/* 03. Header
/* ------------------------------------------------------------------------ */

#sd-header {
    background-color: #FFFFFF;
    width: 100%;
    z-index: 101;
    position: fixed;
    top:0;
    left:0;
    transition: background-color .3s ease-out;

        &.scrolled{
            background-color: rgba(#FFFFFF, 1);
        }
}

.sd-transparent-bg {
    background: none;
    position: absolute;
}

.sd-transparent-bg-mobile {
    background-color: #fff;
}

/* header top */
.sd-header-top {
    background-color: $dark_blue;
    color: #fff;
    font-size: 13px;
    height: 50px;
    line-height: 50px;
    width: 100%;
}

.sd-logo-menu {
    @media (min-width: $sm) {
        height: 110px;
    }
}

/* logo */
.sd-logo {
    float: left;
    margin: 20px 0;
    padding: 0;
    width:180px;

    @media (min-width: $sm) {
        width:300px;
    }
}

.sd-logo-express {
    float: left;
    margin: 20px 0;
    padding: 0;
    width:270px;

    @media (min-width: $sm) {
        width:370px;
    }
}

.sd-logo-text {
    color: #fff;
    font-size: 20px;
}

/* top phone number */
.sd-top-phone {
    color: $light_gray;
    float: left;
    border: none;
    margin: 20px 0 0;
    padding: 0;
    text-align: center;

    @media (min-width: $sm) {
        border-left: 1px solid rgba(255, 255, 255, .1);
        margin-left: 20px;
        margin-top: 30px;
        padding: 5px 0 5px 20px;
    }
}

.sd-top-phone span {
    display: block;
}

.sd-call-text {
    font-size: 12px;
    line-height: 12px;
    padding-bottom: 5px;
}

.sd-top-ph-number {
    font-size: 16px;
    line-height: 16px;
}

.sd-top-ph-number a {
    color: $light_gray;
}

.gototoform{
    :hover {
        cursor:pointer;
    }
}