.wrap-list-counter,
.section-list h3{
	margin-top: 1em;

	@media(min-width: $md){
		margin-top: 0;
	}
}

.section-list .row:nth-of-type(n+3){
	@media(min-width: $md){
		margin-top: 2em;
	}
}

.wrap-list-counter{
	text-align: center;
}

.list-counter{
	display: inline-block;
	font-size: 2em;
	text-align: center;
	line-height: 60px;
	color: #ffffff;
	width: 60px;
	height: 60px;
	background: $light_blue;
	border-radius: 50%;
}

.section-list .wrap-image{
	text-align: center;
}

.section-list img{
    max-height:20em;
}
