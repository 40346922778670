.points {
  .type {
   /* width: 300px;*/
    border-radius: 5px 5px 5px 5px;

    height: 62px;

    p {
      font-family: 'Open Sans';
      font-weight: 800;
      font-size: 29px;
      text-transform: uppercase;
      color: #fff;
      text-align: center;
      padding-top: 10px;
    }

    &.green {
      background-color: #1ABC9C;
     /* border-bottom: 3px solid #17846f;*/
    }
    &.yellow {
      background-color: #dfd204;
    /*  border-bottom: 3px solid #c6bd25;*/
    }
    &.red {
      background-color: #f60000;
     /* border-bottom: 3px solid #b45008;*/
    }

    &.orange {
      background-color: #f39200;
    }

    @media (max-width: $md){
      margin-top: 20px;
    }

  }
  .plan {
   /* width: 300px;*/
    background-color: #f6f6f6;
    border-radius: 0px 0px 5px 5px;
    font-family: 'Open Sans';
    font-size: 90px;
    color: #fff;
    text-align: center;
  }
  .content {
    ul {
      list-style: none;
      font-size: 15px;
      font-family: 'Open Sans';
      color: #000;
      padding: 0px;
      margin: 0px;
      li {
        border-bottom: 1px solid #b9b3b3;
        padding: 0px;
        margin: 0px;
        text-align: center;
        height: 52px;
        line-height: 52px;
      }
    }
  }
  .price {
    height: 52px;
    list-style: none;
    font-size: 15px;
    font-family: 'Open Sans';
    color: #000;
    padding: 0px;
    margin: 0px;
    padding: 0px;
    margin: 0px;
    text-align: center;
    height: 52px;
    line-height: 52px;
  }
}

