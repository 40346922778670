.card {
    text-align: center;
    flex: 1 1 auto;

    .card-wrap{
        height: 100%;
        padding: 3em 2em;
        background: #ffffff;
        box-shadow: 0 0 5px rgba(#d3d3d3, .5);  
        border-radius: $border_radius;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        div{
            max-width: 100%;
        }
    }

    img{
        margin-bottom: 2em;
    }

    .btn-orange{
        margin-top: 2em;
    }

    @media (min-width: $sm){
        padding: 0 5px;
    }
}

.section-cards{
    @media (min-width: $sm){
        .row{
            display: flex;
            justify-content: center;
            align-items: stretch;
        }
    }
}