/*FOOTER*/

#sd-footer {
    background-color: $dark_blue;
    font-size: .8em;
    padding-top: 20px;
    color: $light_gray;
	font-weight: bold;
}

.sd-footer-sidebar-widget {
    text-align: center;
}

.sd-footer-widget-title {
    color: #fff;
    display: inline-block;
    font-weight: 600;
}

.sd-footer-widgets a {
    color: $light_gray;

    &:hover {
        color: #00b2ce;
    }
}

.sd-footer-menu-social {
    border-top: 1px solid #455a70;
    padding: 30px 0;
}

.sd-footer-social {
    float: right;
}

.sd-footer-social a {
    color: $light_gray;
    margin-left: 10px;

    &:first-child {
        margin-left: 0;
    }

    &:hover {
        color: #00b2ce;
    }
}

.sd-copyright-wrapper {
    background-color: darken($dark_blue, 2%);
}

.sd-copyright {
    color: $light_gray;
    padding: 1em 0;
    position: relative;
}

.sd-copyright a {
    color: $light_gray;
}

.sd-copyright-text{
    margin: 0;
}