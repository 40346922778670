/*TABS*/

.sd-tab-section-title {
    display: inline-block;
    position: relative;
    top: 36px;
    left: 0;
}

@media (max-width: $md) {
    .sd-tab-section-title {
        text-align: center;
        padding-top: 20px;
        position: static;
        top: 0;
        height:auto;
    }
}

@media (min-width: $lg) {
    .sd-insurance-tabs ul {
        width: 294px;
    }
}

.sd-insurance-tabs ul {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 244px;
}

@media (max-width: $md) {
    .sd-insurance-tabs ul {
        width: auto;
        float: none;
        padding: 0;
    }
}

@media (max-width: $md) {
    .sd-insurance-tabs ul li span {
        text-align: center;
    }
}

.sd-insurance-tabs ul li span {
    border-bottom: 1px solid #dcdcdc;
    color: #304254;
    display: block;
    font-size: 18px;
    line-height: 20px;
    padding: 30px 20px;
    height: 100px;
}

.sd-insurance-tabs img {
    max-width:112%;
}

@media (max-width: 768px) {
    .sd-insurance-tabs img {
        max-width: 100%;
    }
}

@media (min-width: 979px) {
    .sd-insurance-tabs ul li:last-child span {
        border-bottom: none;
    }
}

.sd-panel-wrap {
    padding: 50px 40px;
    position: relative;
}

.sd-panel-icon {
    background-color: #00b2ce;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    display: block;
    height: 100px;
    left: 40px;
    padding-top: 50px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 55px;
}

.sd-panel-icon i {
    color: #fff;
    font-size: 30px;
}

.sd-panel-title {
    font-size: 24px;
    padding-bottom: 50px;
    padding-left: 80px;
}

.sd-panel-img {
    height: 424px;
    margin-top: 60px;
}