.presses {
	overflow: hidden;

	.box_header {
		width: auto;
		h2 {
			background-color: #329bcd;
			color: #fff;
			font-size: 26px;
			line-height: 36px;
			font-weight: bold;
			float: left;
			overflow: hidden;
			padding: 0 10px;
			margin: 0;
			@media screen and (max-width: 1199px) {
				float: none;
				padding: 10px 7px;
				margin: 0;
				line-height: 25px;
				text-align: center;
			}
		}
	}
	header {
		overflow: hidden;
	}
	.border-bottom-blue {
		border-bottom: 1px solid #329bcd;
	}
	.img_article_desktop {
		display: block;
		height: 250px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		@media (min-width: 1200px) {
			height: 140px;
		}
		@media (min-width: 992px) {
			height: 175px;
			margin-bottom: 0;
		}
		@media (min-width: 992px) {
			height: 150px;
		}
	}
	.author {
		p {
			margin: 10px 0;
			font-size: .8em;
			color: #8899a6;
		}
	}
	.more {
		float: right;
		font-weight: bold;
		line-height: 35px;
	}
}