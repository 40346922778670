/*FORM*/

.sd-quote-form {
    background-color: #fff;
    border: 1px solid #f3f3f3;
    border-radius: $border_radius;
    padding: 1em;
    position: relative;
    margin-bottom: 30px;
}
.sd-quote-form  div.row {
	margin:20px 0px;
}
.sd-quote-form  h4 {
   padding: 5px 10px;
   background-color: $light_gray;
   color: $light_blue;
}
.sd-quote-form p {
    margin: 0 0 20px;
}

.sd-quote-form input,
.sd-quote-form textarea {
    background: none;
    border: 2px solid #e9e9e9;
    border-radius: 0;
    border-width: 0 0 2px;
    color: #989898;
    max-width: 100%;
    padding: 0;
    position: relative;
    z-index: 1;
}

.sd-quote-form textarea {
    height: 100px;
}

.sd-quote-form input:focus,
.sd-quote-form textarea:focus {
    border: 2px solid #00b2ce;
    border-width: 0 0 2px;
    outline: none;
}
.sd-quote-form input{
    color: black;
}

.sd-quote-form-product select {
    -webkit-appearance: none;
    background: none;
    border: 2px solid #e9e9e9;
    border-width: 0 0 2px;
    color: #989898;
    height: 50px;
    outline: none;
    width: 100%;
}

.sd-quote-form-product span {
    display: inline-block;
    width: 100%;
    position: relative;
}

.sd-quote-form-product span::before {
    background-color: #fff;
    border-bottom: 2px solid #e9e9e9;
    content: '';
    display: block;
    height: 50px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 17px;
}

.sd-quote-form-product span::after {
    color: #d3d3d3;
    content: '<>';
    font: 15px "Consolas", monospace;
    padding: 0 0 2px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 14px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.form-text {
    border: 1px solid #cfd4db;
    border-radius: 3px;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    width: 100%;
}