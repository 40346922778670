
.table{
	border: 1px solid $light_blue;
}

.table,
.table th{
	text-align: center;
}

.table thead{
	background: $light_blue;
	color: #ffffff;

	& > tr > th{
		border: none;
	}
}

.table td{
	width: 20%;
}

.table > tbody > tr > td{
	padding: 1em;
	border-left: 1px solid $light_blue;
	border-right: 1px solid $light_blue;
	border-top: none;
	border-bottom: none;
	vertical-align: middle;
}

.mobile-table{
	text-align: center;
}

.mobile-table > ul > li{
	margin-bottom: 1em;
}

.mobile-table > ul > li > ul{
	border: 1px solid $light_blue; 
}

.mobile-table > ul > li > ul > li{
	padding: .5em;

	&:first-of-type{
		background: $light_blue;
		color: #ffffff;
		font-weight: bold;
	}

	&:nth-of-type(2n+3){
		background: $light_gray;
	}
}