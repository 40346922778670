#sliding-reviews{
	overflow: hidden;
	visibility: hidden;
	position: relative;
	padding: 0;
}

.wrap-avis{
	overflow: hidden;
	position: relative;
	padding: 0;

	& > li{
		float:left;
		width:100%;
		position: relative;
	}
}

.avis{
	background: $light_blue;
	padding: 2em 3em;
	border-radius: $border-radius;

	h4, p{
		color: #ffffff;
	}

	.signature{
		margin-top: 1em;
		text-align: right;
		font-style: italic;
	}
	
}

.wrap-bullets{
	float: right;
	margin-bottom: .5em;

	@media (min-width: $md){
		margin-bottom: 1em;
	}
}

.bullet{
	display: inline-block;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	margin-left: 15px; 
	background-color: $light_gray;
	box-shadow: 0 0 5px #d3d3d3;

	&.active{
		/*background-color: $light_blue;*/
		background-color: #f39200;
	}

	&:hover{
		cursor: pointer;
	}
}

.bullet-express{
	display: inline-block;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	margin-left: 15px;
	background-color: #b7b7b7;
	box-shadow: 0 0 5px #d3d3d3;

	&.active{
		/*background-color: $light_blue;*/
		background-color: #f39200;
	}

	&:hover{
		cursor: pointer;
	}
}

.bullets-express {
	margin-top: 22px;
}

.mgtop30 {
	margin-top:30px;
}

.bg-white {
	background-color: #ffffff !important;
}