
/* ------------------------------------------------------------------------ */
/* 04. Navigation
/* ------------------------------------------------------------------------ */

.sd-boxed .sd-menu-nav {
    margin: 0 -14px 0 -16px;
}

.sd-boxed .sd-menu-nav ul > li:last-child a {
    padding-right: 15px;
}

.sd-pull-left-top-nav {
    float: left;
}

.sd-pull-left-top-nav ul li:first-child a {
    padding-left: 0;
}

.sd-header-top-nav ul li a {
    color: #848a90;

    &:hover {
        color: $light_blue;
    }
}

.sd-menu-wrapper {
    float: right;
    margin-top: 40px;
    margin-bottom: 40px;

    @media only screen and (max-width: $md) {
        display: none;
    }
}

.sd-menu-wrapper ul {
    list-style: none;
}

.sd-menu-content {
    clear: both;
}

.mega-sub-menu {
    padding: 0 -10px;
}

.sd-menu-nav {
    font-family: "Open Sans", Tahoma, sans-serif;
}

.sd-free-quote,
.sd-quote-form .wpcf7-submit {
    border-radius: 4px;
    box-shadow: 0 -4px rgba(0, 0, 0, .2) inset;
    color: #fff;
    display: inline-block;
    float: right;
    font-size: 13px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
    margin: 28px 0;
    outline: none;
    padding: 0 20px;
    position: relative;

    &:hover {
        box-shadow: 0 -3px rgba(0, 0, 0, .2) inset;
        color: #fff;
        opacity: 1;
        top: 1px;
        cursor:pointer;
    }

    &:active {
        box-shadow: 0 0 rgba(0, 0, 0, .2) inset;
        color: #fff;
        opacity: 1;
        top: 3px;
    }

    @media (max-width: $sm) {
        float: none;
        margin-bottom: 20px;
    }
}

.sd-logo-menu-content {
    @media (max-width: $sm) {
        text-align: center;
    }
}

/* top bar menu */

.sd-top-bar-nav ul {
    list-style: none;
}

/* sticky menu */
.sd-sticky-header {
    left: 0;
    width: 100%;
    z-index: 1000;

}
.sticky-wrapper {
    height: auto;
}

.is-sticky .sd-header-style3 {
    padding: 0;
}

.is-sticky .sd-header-style3 .sd-menu-wrapper {
    padding: 0;
}

.is-sticky .sd-sticky-header {
    box-shadow: 0 0 2px rgba(0, 0, 0, .2);
    margin: 0;
}

.sd-boxed .is-sticky .sd-stick {
    left: 0;
}

.mega-sd-menu-button a {
    display: inline-block;
    padding: 0 20px;
    position: relative;
}

#mega-menu-main-header-menu > li.mega-sd-menu-button > a {
    background: none;
    border: 1px solid;
    border-color: $light_blue;
    border-radius: 3px;
    color: $light_blue;

    &:hover{
        border-color: $light_blue;
    }
}

.sd-menu-nav .mega-menu-toggle {
    display: none;
}

ul.mega-sub-menu, 
li.mega-menu-item, 
a.mega-menu-link {
    transition: none;
    border-radius: 0 0 0 0;
    box-shadow: none;
    background: none;
    border: 0;
    bottom: auto;
    box-sizing: border-box;
    clip: auto;
    color: #435061;
    display: block;
    float: none;
    font-family: inherit;
    font-size: 13px;
    height: auto;
    left: auto;
    line-height: 30px;
    list-style-type: none;
    margin: 0;
    min-height: 0;
    opacity: 1;
    outline: none;
    overflow: visible;
    padding: 0;
    position: relative;
    right: auto;
    text-align: left;
    text-decoration: none;
    text-transform: none;
    top: auto;
    vertical-align: baseline;
    visibility: inherit;
    width: auto;
}

#mega-menu-main-header-menu {
    visibility: visible;
    text-align: left;
    padding: 0px 0px 0px 0px;
}

#mega-menu-main-header-menu > li.mega-menu-item {
    margin: 0 10px 0 0;
    display: inline-block;
    height: auto;
}

#mega-menu-main-header-menu > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link, 
#mega-menu-main-header-menu > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link {
    background: rgba(0, 0, 0, 0);
    color: $light_gray;
    font-weight: normal;
    text-decoration: none;
    border-color: $light_blue;
}

#mega-menu-main-header-menu > li.mega-menu-item.mega-toggle-on > a.mega-menu-link, 
#mega-menu-main-header-menu > li.mega-menu-item > a.mega-menu-link:hover/*,
#mega-menu-main-header-menu > li.mega-menu-item > a.mega-menu-link:focus*/ {
    background: rgba(0, 0, 0, 0);
    color: #000000;
    font-weight: normal;
    text-decoration: none;
    border-color: #000000;
}

#mega-menu-main-header-menu > li.mega-menu-item > a.mega-menu-link {
    border-top: 1px solid rgba(255, 255, 255, 0);
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-right: 1px solid rgba(255, 255, 255, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    outline: none;
    text-decoration: none;
    padding: 0px 10px 0px 10px;
    line-height: 40px;
    font-weight: normal;
    height: 40px;
    vertical-align: baseline;
    text-align: left;
    width: auto;
    display: block;
    color: #000000;
    text-transform: none;
    text-decoration: none;
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
}

#mega-menu-main-header-menu a.mega-menu-link {
    cursor: pointer;
    display: inline;
    transition: background 200ms linear, color 200ms linear;
}

#mega-menu-main-header-menu li.mega-menu-flyout.mega-menu-item-has-children > a.mega-menu-link::after, 
#mega-menu-main-header-menu li.mega-menu-flyout li.mega-menu-item-has-children > a.mega-menu-link::after, 
#mega-menu-main-header-menu > li.mega-menu-item-has-children > a.mega-menu-link::after {
    content: '\f140';
    display: inline-block;
    font-family: dashicons;
    margin: 0 0 0 6px;
    vertical-align: top;
    transform: rotate(0);
    color: inherit;
}

/* sidr mobile menu */

.sidr {
    background-color: #2a2e30;
    display: none;
    height: 100%;
    overflow-x: none;
    overflow-y: auto;
    position: absolute;
    position: fixed;
    text-transform: uppercase;
    top: 0;
    width: 100%;
    z-index: 999999;
}

.sidr li a {
    color: #fff;
    display: block;
    padding: 10px 20px;
}

.sidr .sidr-inner {
    padding: 0 0 15px
}

.sidr .sidr-inner > p {
    margin-left: 15px;
    margin-right: 15px
}

.sidr.right {
    left: auto;
    right: -260px
}

.sidr.left {
    left: -260px;
    right: auto
}

.sidr > p {
    margin-left: 15px;
    margin-right: 15px
}

.sidr ul {
    border-top: 1px solid #2f3336;
    margin: 0 0 15px;
    padding: 0;
}

.sidr-class-sub-menu,
.sidr-class-mega-sub-menu {
    display: none;
}

.sidr ul li {
    border-bottom: 1px solid #2f3336;
    display: block;
    margin: 0;
    padding: 0;

    &:last-child {
        border: none;
    }
}

.sidr ul li:hover > a,
.sidr ul li:hover > span,
.sidr ul li.active > a,
.sidr ul li.active > span,
.sidr ul li.sidr-class-active > a,
.sidr ul li.sidr-class-active > span {
    background-color: $light_blue;
}

.sidr ul li a,
.sidr ul li span {
    display: block;
    padding: 10px 15px;
    text-transform: uppercase;
}

.sidr ul li span {
    padding-right: 20px;
}

.sidr ul li ul {
    margin: 0
}

.sidr ul li ul li a {
    padding-left: 20px;
}

.sidr ul li ul li ul li a {
    padding-left: 30px;
}

.sidr form {
    margin: 0 15px
}

.sidr input[type="text"],
.sidr input[type="password"],
.sidr input[type="date"],
.sidr input[type="datetime"],
.sidr input[type="email"],
.sidr input[type="number"],
.sidr input[type="search"],
.sidr input[type="tel"],
.sidr input[type="time"],
.sidr input[type="url"],
.sidr textarea,
.sidr select {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    clear: both;
    color: rgba(255, 255, 255, 0.6);
    display: block;
    margin: 0 0 10px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
}

.sidr input[type=checkbox] {
    clear: none;
    display: inline;
    width: auto;
}

.sidr input[type=button],
.sidr input[type=submit] {
    background: #fff;
    color: #333;

    &:hover {
        background: rgba(255, 255, 255, 0.9)
    }
}

.sidr-class-menu-item-has-children,
.sidr-class-mega-menu-item-has-children {
    position: relative;
}

.sidr-chevron {
    color: #fff;
    cursor: pointer;
    font-size: 11px;
    height: 44px;
    line-height: 28px;
    padding-right: 20px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    width: 50px;
}

.sd-responsive-menu-close {
    cursor: pointer;
    display: block;
    height: 45px;
    line-height: 45px;
    width: 100%;
}

.sd-responsive-menu-close i {
    color: #f39200;
    font-size: 18px;
    padding-left: 15px;
}

.sd-responsive-menu-close .fa-times {
    float: right;
    margin: 13px 15px 0 0;
}

.sidr-class-mega-block-title {
    display: none;
}

.sidr-class-recentcomments a {
    display: inline-block;
}

.sidr-class-recentcomments span {
    display: inline-block;
    padding: 10px 0 0 30px;
}

.sidr-class-sd-minicart-icon {
    position: relative;
}

.sidr-class-fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    transform: translate(0, 0);
}

.sidr-class-fa-shopping-cart::before {
    content: "\f07a";
}

.sidr-class-sd-minicart-icon span {
    padding: 0;
}

.sidr-class-sd-minicart-icon .sd-items-count {
    left: 25px;
    right: auto;
}

/* toggle repspoinsive menu */
.sd-responsive-menu-toggle {
    display: inline-block;
    margin-left: 20px;
    margin-top: 20px;
    width: auto;
    float: right;
    text-align: center;
    cursor: pointer;
    @media (min-width: $sm) {
        margin-top: 33px;
    }
    @media (min-width: $md) {
        display: none;
        margin-top: 20px;
        padding: 0;
        width: 100%;
    }
}

.sd-responsive-menu-toggle a {
    background-color: #000000;
    color: #FFFFFF;
    display: inline-block;
    height: 40px;
    line-height: 42px;
    padding: 0 15px;
    border-radius: $border_radius;
}

.menu-toggle-express {
    @media screen and (max-width: 361px){
        margin-top: 20px;
        width: auto;
        float: none;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding-bottom: 20px;
    }


}

.container-toggle {
    float: right;

    @media (min-width: $sm) {
        float: none;
    }
}

.logo-link{
    height: 20px;
}

.menu-active {

   /* background: 0 0;
    color: #000;
    font-weight: 400;
    text-decoration: none;*/
    border-color: #000 !important;

}