/* ------------------------------------------------------------------------ */
/* 01. General
/* ------------------------------------------------------------------------ */
/* load the default Google font */
@import url(//fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,800,300,600,700);

@font-face {
    font-family: star;
    font-style: normal;
    font-weight: 400;
}

body {
    height: 100%;
    background-color: #fff;
    color: $text_color;
    font-family: "Open Sans", Arial, Tahoma, sans-serif;
    font-weight: 400;
    font-size: 14px; 
    position: relative;
    -webkit-text-stroke: 0.001px transparent;
    -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;

    @media (min-width: $sm){
        font-size: 16px;
    }
}

input[type="email"],
input[type="submit"],
input[type="text"],
textarea {
    -webkit-appearance: none;
}

section{
    padding: 25px 0;

    /* Every odd section has a gray background except the first one (starts counting at 3) */
    &:nth-of-type(2n+3){
        background-color: $light_gray;
    }

    @media (min-width: $md){
        padding: 50px 0;
    }
}

/* ------------------------------------------------------------------------ */
/* 02. Typography Styling
/* ------------------------------------------------------------------------ */

/* Links */
a {
    color: $light_blue;
    outline: none;
    text-decoration: none;
    transition: color .2s ease-in;

    &:hover {
        text-decoration: none;
        color: $light_blue;
    }

    &:focus,
    &:active {
        outline: none;
    }
}

/* Blockquotes */
blockquote {
    border:none;

    p {
        margin: 0;
    }
}

/* Titles */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $title_color;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
}

h1, .sizeh1 {
    font-size: 3em;
}

h2 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 1em;

   /* @media(min-width: $md){
        margin-bottom: 2em;
    }*/
}

.sizeh2 {
    font-size: 2em;
}

h3, .sizeh3 {
    font-size: 1.5em;
    margin-bottom: .5em;
}

h4, .sizeh4 {
    font-size: 1.3em;
}

h5, .sizeh5 {
    font-size: 1.2em;
}

h6, .sizeh6 {
    font-size: 1.1em;
}

p {
    margin-bottom: .5em;
}

label{
    font-weight: inherit;
}

/* Images Styling */
img{
    max-width:100%;
    height: auto;
}

img.alignleft {
    float: left;
    vertical-align: middle;
    margin-right: 20px;
}

img.alignright {
    float: right;
    vertical-align: middle;
    margin-left: 20px;
}

img.aligncenter {
    display: block;
    margin: 0 auto;
    clear: both;
}

.aligncenter {
    margin: 0 auto;
    clear: both;
    display: block;
}
/* Lists */
ul,
ol {
    list-style:none;
    margin: 0;
    padding: 0;
}

.btn-orange{
    display: inline-block;
    background: #f39200;
    color: #ffffff;    
    padding: .5em 1em;
    border-radius: $border_radius;
    transition: background-color .2s ease-out;

    &:hover{
        background: #f06d25;
        color: #ffffff;
    }
}

.btn-bleu{
    display: inline-block;
    background: #2b98cc;
    color: #ffffff;
    padding: .5em 1em;
    border-radius: $border_radius;
    transition: background-color .2s ease-out;

    &:hover{
        background: #304254;
        color: #ffffff;
    }
}

.border-box {
    border-style: solid;
    border-width: 1px;
    border-color: $light_gray;
    margin: 0px;
}

.img-container {
    text-align: center;
}

.img-responsive {
    margin:0 auto;
}

.text-justify {
    text-align: justify;
}

.margin-bottom100 {
    margin-bottom: 100px;
}

.margin-bottom20 {
    margin-bottom: 20px;
}

.margin-top100 {
    margin-top: 100px;
}

.padding-top100 {
    padding-top: 100px;
}

.padding-top10 {
    padding-top: 10px;
}

.padding-bottom100 {
    padding-bottom: 100px;
}

.padding-top35 {
    padding-top: 35px;
}

.padding-bottom75 {
    padding-bottom: 75px;
}

.margin-top20 {
    margin-top: 20px;
}

.icon-confiance {
    color:#f39200;
    margin-right: 15px;
    font-size: 24px;
}

.margin-bottom30 {
    margin-bottom: 30px;
}

.padding-bottom35 {
    padding-bottom: 35px;
}

.border-temoignages {
    @media (min-width: 992px) {
        border-right: 1px solid #b9b3b3;
    }

}

.img-center {
    display: block;
    margin-right: auto;
    margin-left: auto;
}