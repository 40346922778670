.section-contact{
    h3{
        text-align: center;
        margin-bottom: 30px;
    }

    li{
        margin-bottom: .5em;
        text-align: center;
    }

    .border-box{
        padding: 2em;
    }

    /*[class*='col']:nth-of-type(n+2){
        margin-top: 3em
    }*/

    @media (min-width: $sm){
       /* ul{
            padding-left: 30% !important; 
        }*/
    }

    @media (min-width: $md){
       /* [class*='col']:nth-of-type(n+2){
              margin-top: 0
          }*/
    }
}

.gmap{
    padding: 0;

    [class*='col']{
        padding: 0;
    }
}